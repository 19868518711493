<script>
import { Notification } from 'epmc-patterns/components/v2'

export default {
  metaInfo() {
    return {
      title: 'MSS maintenance - Europe PMC',
    }
  },
  components: { Notification },
}
</script>
<template>
  <div id="mss-maintenance-page">
    <div class="grid-row">
      <div class="col-10">
        <div>
          <img src="@/assets/epmc_plus.png" alt="Europe PMC Plus" />
        </div>
        <notification notification-style="error">
          Europe PMC plus manuscript submission system is offline Wednesday, 30
          June, 10 a.m. to 1 p.m. BST, due to essential maintenance. We
          applogise for any inconvenience caused.
        </notification>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#mss-maintenance-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  .grid-row {
    display: flex;
    justify-content: center;
    .notification {
      margin-top: $base-unit * 4;
    }
  }
}
</style>
